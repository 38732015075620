<template>
  <b-row class="hp-authentication-page">
    <left-item />

    <b-col cols="12" lg="6" class="py-sm-64 py-lg-0">
      <b-row align-v="center" align-h="center" class="h-100 mx-4 mx-sm-n32">
        <b-col
          cols="12"
          md="9"
          xl="7"
          class="col-xxxl-5 px-8 px-sm-0 pt-24 pb-48"
        >
          <h1 class="mb-0 mb-sm-24">Atur Ulang Kata Sandi</h1>
          <p class="mt-sm-8 mt-sm-0 text-black-60">
            Silahkan masukkan kata sandi baru
          </p>

          <b-form class="mt-16 mt-sm-32 mb-8" @submit.prevent="resetPassword">
            <b-form-group label="Token :" label-for="token" class="mb-16">
              <b-form-input
                id="token"
                v-model="field.token"
                placeholder="Masukkan Token yang anda dapatkan melalui email anda"
                :state="validation.token"
              />
              <b-form-invalid-feedback :state="validation.token">
                {{ fieldErrors.token }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Password :"
              label-for="resetPassword"
              class="mb-16"
            >
              <b-form-input
                id="resetPassword"
                v-model="field.password"
                type="password"
                placeholder="Masukkan Password"
                :state="validation.password"
              />
              <b-form-invalid-feedback :state="validation.password">
                {{ fieldErrors.password }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Konfirmasi Password :"
              label-for="resetConfirmPassword"
              class="mb-16"
            >
              <b-form-input
                id="resetConfirmPassword"
                v-model="field.password_confirmation"
                type="password"
                placeholder="Masukkan Konfirmasi Password"
                :state="validation.password_confirmation"
              />
              <b-form-invalid-feedback
                :state="validation.password_confirmation"
              >
                {{ fieldErrors.password_confirmation }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-button
              variant="danger"
              class="w-100"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                small
                label="Small Spinner"
                v-if="isLoading"
              ></b-spinner>
              <span v-else>Reset Password</span>
            </b-button>
          </b-form>

          <div class="hp-form-info text-center">
            <span class="text-black-80 hp-text-color-dark-40 hp-caption mr-4">
              Kembali ke Halaman
            </span>

            <b-link
              class="hp-cursor-pointer text-danger-1 hp-text-color-dark-primary-2 hp-caption"
              to="/pages/authentication/login"
            >
              Login
            </b-link>
          </div>

          <b-modal
            v-model="showModal"
            :hide-header="hideHeader"
            :hide-footer="hideFooter"
          >
            <p class="hp-p1-body mb-0">
              Reset Password Berhasil, Silahkan Klik Tombol Menuju Halaman Login
            </p>

            <div class="d-flex justify-content-end flex-wrap mt-32">
              <router-link to="/pages/authentication/login">
                <b-button class="ml-16 btn-danger-1">
                  Pergi Ke Halaman Login
                </b-button>
              </router-link>
            </div>
          </b-modal>

          <footer-item />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
  BModal,
  BFormInvalidFeedback,
} from "bootstrap-vue";

import LeftItem from "../LeftItem.vue";
import FooterItem from "../FooterItem.vue";

import { reset } from "../../../../api/auth/authAPI";

export default {
  name: "ResetPasswordPage",
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BSpinner,
    BModal,
    LeftItem,
    FooterItem,
  },
  data() {
    return {
      field: {
        token: "",
        password: "",
        password_confirmation: "",
      },
      fieldErrors: {
        token: undefined,
        password: undefined,
        password_confirmation: undefined,
      },
      showModal: false,
      isLoading: false,
      hideHeader: true,
      hideFooter: true,
    };
  },
  watch: {
    "field.token": function (newValue) {
      if (newValue) {
        this.fieldErrors.token = null;
      }
    },
    "field.password": function (newValue) {
      if (newValue) {
        this.fieldErrors.password = null;
      }
    },
    "field.password_confirmation": function (newValue) {
      if (newValue) {
        this.fieldErrors.password_confirmation = null;
      }
    },
  },
  methods: {
    resetPassword() {
      this.isLoading = true;
      reset({
        token: this.field.token,
        password: this.field.password,
        password_confirmation: this.field.password_confirmation,
      })
        .then(() => {
          this.isLoading = false;
          this.$bvToast.toast(
            "Token sudah kami kirim, Silakan periksa email Anda untuk.",
            {
              title: "Berhasil",
              variant: "success",
              solid: true,
            }
          );
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            const errorData = error.response.data;
            if (errorData.code === 400 || errorData.status === false) {
              const errorMessage = errorData.message.split("\\n");

              errorMessage.forEach((msg) => {
                if (msg.includes("The password field is required")) {
                  this.fieldErrors.password = msg;
                } else if (
                  msg.includes("The password confirmation field is required")
                ) {
                  this.fieldErrors.password_confirmation = msg;
                } else if (msg.includes("Token")) {
                  this.fieldErrors.token = msg;
                }
              });
            }
          } else {
            // Handle other types of errors (e.g., network issues)
            console.error(error);
          }
        });
    },
  },
  computed: {
    validation() {
      return {
        token: this.fieldErrors.token ? false : null,
        password: this.fieldErrors.password ? false : null,
        password_confirmation: this.fieldErrors.password_confirmation
          ? false
          : null,
      };
    },
  },
};
</script>
